import React, {useState} from "react"
import { Link } from "gatsby"
import { graphql } from 'gatsby'
import Layout from "../components/layout"
import Seo from "../components/seo"
import amBlogHero from "../images/am-blog.jpg"
import HtmlParser from '../helpers/htmlParser';
import '../utils/fontawesome';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { GatsbyImage } from "gatsby-plugin-image"

function hideFilterComponent() {

    if (document.getElementById) {
        var fcClose = document.getElementById("blog__sidebar--boxes");
        fcClose.classList.remove("visible__filter");
        fcClose.classList.add("dismiss");
        var fcIconClose = document.getElementById("blog__sidebar--icon");
        fcIconClose.classList.remove("hide__filter");
        var fcIconDivClose = document.getElementById("blog__sidebar--container");
        fcIconDivClose.classList.remove("hide__filter");
    }
    return false;
  
  }
  
  function showFilterComponent() {
  
    if (document.getElementById) {
      var fcClose = document.getElementById("blog__sidebar--boxes");
      fcClose.classList.add("visible__filter");
      fcClose.classList.remove("dismiss");
      var fcIconClose = document.getElementById("blog__sidebar--icon");
      fcIconClose.classList.add("hide__filter");
      var fcIconDivClose = document.getElementById("blog__sidebar--container");
      fcIconDivClose.classList.add("hide__filter");
    }
    return false;
  
  }

function BlogDivs(data, catfilter, datefilter) {

    if (catfilter === "All" && datefilter === "All") {
        var blogData = data;
    }

    if (catfilter !== "All") {
        const catfilterarray = data.filter(x => x.fieldBlogCategory?.[0]?.entity.title === catfilter);
        blogData = catfilterarray;
    }

    if (datefilter !== "All") {
        const datefilterarray = data.filter(x => x.fieldDate.value.includes(datefilter));
        blogData = datefilterarray;
    }

    if (catfilter !== "All" && datefilter !== "All") {
        const catfilterarray = data.filter(x => x.fieldBlogCategory?.[0]?.entity.title === catfilter);
        const datefilterarray = catfilterarray.filter(x => x.fieldDate.value.includes(datefilter));
        blogData = datefilterarray;
    }

    let alt = "";
    let pageElements = [];
    blogData.forEach(element => {
    const rawnewsdate = element.fieldDate.value
    const newsdate = rawnewsdate.replace(/-/g,'/');

    if (element != null) {
            pageElements.push(
                <article className="blog__boxes--box blog__boxes--box" key={element.title}>
                    <div className="blog__box--elements blog__boxes--box--elements">
                        <div className="blog__box--image">
                                {/*<Img
                                    alt={alt}
                                    fluid={element?.fieldMedia?.[0]?.notStyledImage?.childImageSharp?.fluid}
                                />*/}
                                <GatsbyImage alt={alt} image={element?.fieldMedia?.[0]?.notStyledImage?.childImageSharp?.gatsbyImageData} />
                        </div>
                        <div className="blog__box--text">
                            <div className="blog__box--text--header">
                                <div className="blog__box--text--header--type">
                                <FontAwesomeIcon icon={'comments'} />
                                </div>
                                <div className="blog__box--links">
                                    <ul>
                                        <a href="https://www.facebook.com/activemediausa">
                                            <li className="blog__box--links--facebook">
                                                <FontAwesomeIcon icon={['fab', 'facebook-f']} />
                                                <p className="hide-this-element">Facebook</p>
                                            </li>
                                        </a>
                                        <a href="https://twitter.com/ActiveMedia_USA">
                                          <li className="blog__box--links--twitter">
                                                <FontAwesomeIcon icon={['fab', 'twitter']} />
                                                <p className="hide-this-element">Twitter</p>
                                          </li>
                                        </a>
                                        <a href="https://www.linkedin.com/company/activemedia">
                                          <li className="blog__box--links--linkedin">
                                                <FontAwesomeIcon icon={['fab', 'linkedin-in']} />
                                                <p className="hide-this-element">Linkedin</p>
                                          </li>
                                        </a>
                                    </ul>
                                </div>
                            </div>
                            <div className="blog__box--body">
                                <div className="blog__box--body--title">
                                    <h3><Link to={element?.entityUrl?.path}>{element?.title}</Link></h3>
                                </div>
                                <div className="blog__box--body--info">
                                    <p className="date"><FontAwesomeIcon icon={'calendar'} /> {newsdate}</p>
                                </div>
                                <div className="blog__box--body--category">
                                    <Link to="#">{element?.fieldBlogCategory?.[0]?.entity.title}</Link>
                                </div>
                                <div className="blog__box--body--text">
                                    <HtmlParser html={ element?.body?.processed !== undefined ? element.body.processed.substring(0, 450) : '' }/>
                                </div>
                                <div className="blog__box--body--btn">
                                    <Link to={element?.entityUrl?.path} >Read More</Link>
                                </div>
                            </div>
                        </div>
                    </div>
                </article>
            )
        } 
    });
    return (
        <div className="blog__boxes">{pageElements}</div>
    )
}

const Blog = ({ data, location }) => {
    
    //console.log(data.drupal.nodeQuery.entities[0]);
    const alldata = data.drupal.nodeQuery.entities;
    const mixdata = alldata.filter(x => x !== null);

    const [pageFilter, setPageFilter] = useState(mixdata);
    const [catFilter, setCatFilter] = useState("All");
    const [dateFilter, setDateFilter] = useState("All");

    if(typeof window !== 'undefined' && window.document) {
        if (typeof document !== `undefined`) {
            if (location.pathname === "/blog"){
                {
                    function getScrollTop() {
                        if (typeof window.pageYOffset !== "undefined") {
                            // Most browsers
                            return window.pageYOffset;
                        }
                    
                        var d = document.documentElement;
                        if (typeof d.clientHeight !== "undefined") {
                            // IE in standards mode
                            return d.scrollTop;
                        }
                    
                        // IE in quirks mode
                        return document.body.scrollTop;
                    }
                    
                    if (location.pathname === "/blog") {
                        window.onscroll = function () {
                            if (document.getElementById("blog__sidebar--container") !== null) {
                                var box = document.getElementById("blog__sidebar--container");
                                var scroll = getScrollTop();
                                
                                if (scroll <= 600) {
                                    box.style.top = "600px";
                                } else {
                                    box.style.top = (scroll - 300) + "px";
                                }
                            }  else {
                                return false
                            }
                        };
                    }
                };
            }
        }
     }
    
    return (
        <Layout>
            <Seo title="Blog" />
            <div className="hero__image blog">
                <figure>
                    <img src={amBlogHero} alt="ActiveMedia hero"></img>
                </figure>
                <div className="hero__pages">
                    <div className="hero__news--center">
                        <h1>Blog</h1>
                        <p><span>Learn Something New With ActiveMedia.</span></p>
                    </div>
                </div>
                <div id="mobile__hero--btn" className="hero__btn news__hero--btn">
                    <Link to="#breadcrumb">Find Out More</Link>
                </div>
            </div>
            <div className="breadcrumb__container news__breadcrumb--container" id="breadcrumb">
                <div className="breadcrumb__container--box">
                    <div className="breadcrumb">
                        <Link to="/">Home </Link>/<span> Blog</span>
                    </div>
                </div>
            </div>
            <div className="main__container press-main__container">
                <section id="blog__sidebar--container" className="blog__sidebar">
                    <div id="blog__sidebar--icon" className="blog__sidebar--icon" aria-hidden="true" onKeyDown={() => showFilterComponent()} onClick={() => showFilterComponent()}>
                    <FontAwesomeIcon icon={['fas', 'filter']} />
                    </div>
                    <div id="blog__sidebar--boxes" className="blog__sidebar--boxes">
                        <div id="blog__sidebar--close" className="blog__sidebar--close" aria-hidden="true" onKeyDown={() => hideFilterComponent()} onClick={() => hideFilterComponent()}>
                        <FontAwesomeIcon icon={['fas', 'times']} />
                        </div>
                        <div className="blog__sidebar--boxes--box">
                            <div className="blog__sidebar--categories">
                                <div className="blog__sidebar--title">
                                    <h2>Categories</h2>
                                </div>
                                <div className="blog__sidebar--links">
                                    <button onClick={() =>setCatFilter("Web Design & Development")}>Web Design & Development</button>
                                    <button onClick={() =>setCatFilter("Online Marketing")}>Online Marketing</button>
                                </div>
                            </div>
                            <div className="blog__sidebar--archive">
                                <div className="blog__sidebar--title">
                                    <h2>Archive</h2>
                                </div>
                                <div className="blog__sidebar--links">
                                    <button onClick={() =>setDateFilter("2020")}>2020</button>
                                    <button onClick={() =>setDateFilter("2019")}>2019</button>
                                    <button onClick={() =>setDateFilter("2018")}>2018</button>
                                    <button onClick={() =>setDateFilter("2017")}>2017</button>
                                    <button onClick={() =>setDateFilter("2016")}>2016</button>
                                    <button onClick={() =>setDateFilter("2015")}>2015</button>
                                    <button onClick={() =>setDateFilter("2014")}>2014</button>
                                    <button onClick={() =>setDateFilter("2013")}>2013</button>
                                    <button onClick={() =>setDateFilter("2012")}>2012</button>
                                    <button onClick={() =>setDateFilter("2011")}>2011</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
                <section className="blog__main">
                    <div className="blog__main--fafaicon">
                        <div className="blog__main--fafaicon--container">
                            <div className="blog__main--fafaicon--details"><FontAwesomeIcon icon={'coffee'} /></div>
                        </div>
                    </div>
                    <h2>The place to be to learn more about online marketing, web development, and everything in between.</h2>
                    <div className="blog__main--filter-options">
                        <div className={`${catFilter.toLowerCase()} blog__main--filter-options--cat`}>
                            <button onClick={() =>setCatFilter("All")}>{catFilter} <FontAwesomeIcon icon={['fas', 'times']} /></button>
                        </div>
                        <div className={`${dateFilter.toLowerCase()} blog__main--filter-options--date`}>
                            <button onClick={() =>setDateFilter("All")}>{dateFilter} <FontAwesomeIcon icon={['fas', 'times']} /></button>
                        </div>
                    </div>
                    <div>
                        {BlogDivs(pageFilter, catFilter, dateFilter)}
                    </div>
                </section>
            </div>
        </Layout>
    )
}

export default Blog

export const data = graphql`
{
    drupal {
      nodeQuery(filter: {conditions: [
    {operator: EQUAL, field: "type", value: ["page_blog"]},
    ]}, limit: 9999999, sort: {field: "created", direction: DESC}) {
        entities {
          entityBundle
          ... on Drupal_NodePageBlog {
            nid
            uuid
            title
            body {
              processed
              summaryProcessed
            }
            fieldDate {
              value
            }
            fieldMedia {
                entity {
                  uuid
                  url
                }
                url
                notStyledImage {
                    childImageSharp {
                        gatsbyImageData
                    }
                  }
              }
            fieldBlogCategory {
              entity {
                title
              }
            }
            entityUrl {
                path
            }
          }
        }
      }
    }
  }
  
`;